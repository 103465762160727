import React from 'react';
import styled from 'styled-components';
import { FaPhone } from 'react-icons/fa';

const CallButton = styled.a`
  display: inline-flex;
  align-items: center;
  background-color: #8cc63f;
  border: 2px solid #8cc63f;
  color: white;
  padding: 12px 24px;
  font-size: 18px;
  font-weight: bold;
  text-decoration: none;
  border-radius: 8px;
  transition: background-color 0.3s ease, transform 0.2s ease;

  &:hover {
    background-color: #79b035;
    transform: scale(1.05);
  }

  &:active {
    background-color: #6fa12e;
    transform: scale(0.95);
  }
`;

export default function CallNowButton() {
  return (
    <CallButton href="tel:+17737658047">
      <FaPhone style={{ marginRight: '8px' }} /> Call Us Now
    </CallButton>
  );
}
