import React from 'react';
import bg1 from '../assets/images/hq.jpg';
import c1 from '../assets/images/carousel/1.jpeg';
import c2 from '../assets/images/carousel/2.jpeg';
import c3 from '../assets/images/carousel/3.jpeg';
import c4 from '../assets/images/carousel/4.jpeg';
import { Carousel } from 'react-responsive-carousel';
import { isMobilePhone } from '../utils/helper';
import '../assets/css/Carousel.css';

export default function CarouselComponent() {
  const screenHeight = isMobilePhone ? window.screen.height - 280 : window.screen.height - 115;
  return (
    <section className="home-slider position-relative" id="home">
      <Carousel
        infiniteLoop={true}
        autoPlay={true}
        showArrows={true}
        showStatus={false}
        interval={3000}
      >
        <div
          className="carousel-item align-items-center active"
          style={{
            backgroundImage: `url(${bg1})`,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            height: isMobilePhone ? screenHeight * 1.2 : screenHeight,
          }}
        >
          <div className="bg-half-260 d-table w-100">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-10 mt-4 pt-2">
                  <div className="title-heading text-center">
                    {/* <h1 className="heading text-white title-dark mb-3">PlayGround </h1> */}
                    <p className="para-desc mx-auto text-white-50">...</p>
                    <div className="mt-4 pt-2"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="carousel-item align-items-center active"
          style={{
            backgroundImage: `url(${c1})`,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            height: isMobilePhone ? screenHeight * 1.2 : screenHeight,
          }}
        >
          <div className="bg-half-260 d-table w-100">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-10 mt-4 pt-2">
                  <div className="title-heading text-center">
                    {/* <h1 className="heading text-white title-dark mb-3">PlayGround</h1> */}

                    <div className="mt-4 pt-2"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="carousel-item align-items-center active"
          style={{
            backgroundImage: `url(${c2})`,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            height: isMobilePhone ? screenHeight * 1.2 : screenHeight,
          }}
        >
          <div className="bg-half-260 d-table w-100">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-10 mt-4 pt-2">
                  <div className="title-heading text-center">
                    {/* <h1 className="heading text-white title-dark mb-3">PlayGround</h1> */}
                    <div className="mt-4 pt-2"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="carousel-item align-items-center active"
          style={{
            backgroundImage: `url(${c3})`,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            height: isMobilePhone ? screenHeight * 1.2 : screenHeight,
          }}
        >
          <div className="bg-half-260 d-table w-100">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-10 mt-4 pt-2">
                  <div className="title-heading text-center">
                    {/* <h1 className="heading text-white title-dark mb-3">PlayGround</h1> */}
                    <div className="mt-4 pt-2"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="carousel-item align-items-center active"
          style={{
            backgroundImage: `url(${c4})`,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            height: isMobilePhone ? screenHeight * 1.2 : screenHeight,
          }}
        >
          <div className="bg-half-260 d-table w-100">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-10 mt-4 pt-2">
                  <div className="title-heading text-center">
                    {/* <h1 className="heading text-white title-dark mb-3">PlayGround</h1> */}
                    <div className="mt-4 pt-2"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Carousel>
    </section>
  );
}
