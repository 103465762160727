import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, Tabs, Tab, Accordion } from 'react-bootstrap';
import { motion } from 'framer-motion';
import { isMobilePhone } from '../utils/helper';
import '../assets/css/Reservation.css';
import CallNowButton from '../components/CallNowButton';
import ScrollToTop from '../components/scroll-to-top';

// ------------------------
// Updated Data for Packages (per PDF)
// ------------------------
const packagesData = [
  {
    id: 1,
    title: 'Semi-private Party',
    description:
      'A semi-private party package with partial use of our space. A $250 non-refundable, non-transferable deposit is required to secure your booking.',
    packageDetails: {
      deposit: 250,
      duration: '2.5 hours',
      extraHourCharge: 120,
      outsideFoodCharge: 40,
      outsideBeverages: 'Not allowed (beverages available on site)',
      setupTime: '15 minutes before',
      roomAvailability:
        'Back room available 7 days a week; Front space available Saturday and Sunday',
    },
    pricing: [
      { kids: 10, basePrice: 425, additionalChild: 38 },
      { kids: 15, basePrice: 550, additionalChild: 34 },
      { kids: 20, basePrice: 670, additionalChild: 29 },
    ],
    whatsIncluded: [
      '2,5 hours open space',
      'Plastic tablecloths',
      'Napkins',
      'Juice box or soda',
      'Pizza',
      'Gift for BIRTHDAY CHILD',
    ],
    availability: 'Booking subject to availability',
  },
  {
    id: 2,
    title: 'Private Party',
    description:
      'Reserve the entire space exclusively for your party. The package cost is the same as the semi-private option plus a $540 rental fee. A $250 non-refundable, non-transferable deposit is required to secure your booking.',
    packageDetails: {
      deposit: 250,
      duration: '3 hours',
      rentalFee: 540,
      extraHourCharge: 220,
      outsideFoodCharge: 0,
      outsideBeverages: 'Not allowed (beverages available on site)',
      setupTime: '15 minutes before',
    },
    pricing: [
      { kids: 10, basePrice: 425 + ' + Rental Fee', additionalChild: 38 },
      { kids: 15, basePrice: 550 + ' + Rental Fee', additionalChild: 34 },
      { kids: 20, basePrice: 670 + ' + Rental Fee', additionalChild: 29 },
    ],
    whatsIncluded: [
      'Exclusive use of the entire space',
      'Premium amenities provided',
      'Plastic tablecloths',
      'Napkins',
      'Juice box or soda',
      'Pizza',
      'Gift for BIRTHDAY CHILD',
    ],
    availability: 'Booking subject to availability',
  },
];

// ------------------------
// Sample FAQ Data
// ------------------------
const faqData = [
  {
    question: 'What are the extra hour charges?',
    answer:
      'For semi-private parties, extra hours cost $120 per hour. For private parties, extra hours cost $220 per hour (subject to availability).',
  },
  {
    question: 'What is included in each package?',
    answer:
      'Each package includes the use of our party areas with specified durations. Additional fees apply for extra hours and outside food (only in the semi-private package).',
  },
  {
    question: 'Can I bring my own birthday cake?',
    answer: 'Yes, you can bring your own birthday cake. It is free of charge with any package.',
  },
  {
    question: 'Can I bring my own food?',
    answer:
      'Yes, you can bring your own food. There is a $40 outside food charge with the semi-private package, while the private package does not incur an outside food charge.',
  },
  {
    question: 'What are the extra hour charges?',
    answer:
      'For semi-private parties, extra hours cost $120 per hour. For private parties, extra hours cost $220 per hour (subject to availability).',
  },
  {
    question: 'Is the deposit returnable?',
    answer:
      'No, the deposit is not returnable or transferable. That means if you cancel your party, the deposit won’t be returned, nor can it be transferred to another person or another day/hour.',
  },
  {
    question: 'Can we bring our own decorations?',
    answer:
      'Yes, you can bring your own decorations. However, they cannot be nailed or glued to the walls. We also do not allow confetti, glitter, or any other decorative particles.',
  },
];

// --------------------------
// Inline Styles
// --------------------------
const styles = {
  page: {
    backgroundColor: '#fff',
    paddingTop: isMobilePhone ? '20%' : '10%',
    paddingBottom: '5%',
  },
  sectionTitle: {
    fontSize: '2.5rem',
    fontWeight: 'bold',
    marginBottom: '1rem',
    textAlign: 'center',
  },
  sectionSubTitle: {
    fontSize: '1.8rem',
    fontWeight: 'bold',
    marginBottom: '1rem',
    textAlign: 'center',
  },
  sectionDescription: {
    fontSize: '1.1rem',
    color: '#666',
    marginBottom: '2rem',
    textAlign: 'center',
  },
  twoColumnRow: {
    marginTop: '2.5rem',
    marginBottom: '3.5rem',
  },
  additionalInfoList: {
    textAlign: 'left',
    fontSize: '1rem',
    lineHeight: '1.5',
  },
  pulsingButton: {
    animation: 'pulse 2s infinite',
  },
  callButton: {
    backgroundColor: '#8CC63F', // Green color
    borderColor: '#8CC63F', // Matching border
    color: 'white', // White text for contrast
    fontSize: '18px',
    padding: '10px 20px',
  },
};

// --------------------------
// Back To Top Button Component
// --------------------------
function BackToTop() {
  const [visible, setVisible] = useState(false);
  const toggleVisibility = () => setVisible(window.pageYOffset > 300);
  useEffect(() => {
    window.addEventListener('scroll', toggleVisibility);
    return () => window.removeEventListener('scroll', toggleVisibility);
  }, []);
  // const scrollToTop = () => window.scrollTo({ top: 0, behavior: 'smooth' });
  return visible && <ScrollToTop />;
}

// --------------------------
// Function to render pricing tabs for a package
// --------------------------
const PricingTabs = ({ pricing }) => (
  <Tabs defaultActiveKey={pricing[0].kids} id={`pricing-tabs`} className="mt-3" fill>
    {pricing.map((tier) => (
      <Tab eventKey={tier.kids} title={`${tier.kids} Kids`} key={tier.kids}>
        <div className="mt-3" style={{ fontSize: '1rem' }}>
          <p>
            <strong>Base Price:</strong> ${tier.basePrice}
          </p>
          <p>
            <strong>Additional Child:</strong> ${tier.additionalChild} per child
          </p>
        </div>
      </Tab>
    ))}
  </Tabs>
);

// --------------------------
// Function to render additional information for a package
// --------------------------
const renderAdditionalInfo = (packageDetails, roomAvailability) => {
  const infoItems = [
    `Deposit: $${packageDetails.deposit}`,
    packageDetails.rentalFee !== undefined ? `Rental Fee: $${packageDetails.rentalFee}` : null,
    `Duration: ${packageDetails.duration}`,
    `Extra Hour Charge: $${packageDetails.extraHourCharge} per hour`,
    `Outside Food Charge: ${
      packageDetails.outsideFoodCharge ? `$${packageDetails.outsideFoodCharge}` : 'No charge'
    }`,
    `Outside Beverages: ${packageDetails.outsideBeverages}`,
    `Setup Time: ${packageDetails.setupTime}`,
    roomAvailability ? `Room Availability: ${roomAvailability}` : null,
  ].filter(Boolean);

  return (
    <ul style={styles.additionalInfoList}>
      {infoItems.map((item, index) => (
        <li key={index}>{item}</li>
      ))}
      <li>10% gratuity will be added to the final bill</li>
      <li>Changes to the number of attendees must be confirmed in advance</li>
      <li>Decorations must be approved by the venue; no confetti allowed</li>
    </ul>
  );
};

// --------------------------
// Main Reservation Component
// --------------------------
export default function ReservationEnhanced() {
  return (
    <div style={styles.page}>
      <Container>
        {/* Intro Section */}
        {/* <Row className="justify-content-center text-center">
          <Col md={10}>
            <motion.h2
              style={styles.sectionDescription}
              initial="hidden"
              animate="visible"
              variants={{ hidden: { opacity: 0, y: -50 }, visible: { opacity: 1, y: 0 } }}
              transition={{ duration: 0.8, ease: 'easeInOut' }}
            >
              Choose the perfect party package for your celebration.
            </motion.h2>
          </Col>
        </Row> */}

        {/* Render each party package as a separate section */}
        {packagesData.map((pkg) => (
          <Row style={styles.twoColumnRow} key={pkg.id}>
            <Col md={12}>
              <motion.h2
                style={styles.sectionTitle}
                initial="hidden"
                animate="visible"
                variants={{ hidden: { opacity: 0, y: -50 }, visible: { opacity: 1, y: 0 } }}
                transition={{ duration: 0.8, ease: 'easeInOut' }}
              >
                {pkg.title}
              </motion.h2>
            </Col>
            <Col md={6}>
              <Card className="shadow-sm border-0">
                <Card.Body>
                  {/* <Card.Title className="text-center">{pkg.title}</Card.Title> */}
                  {pkg.description && (
                    <Card.Text className="text-center" style={{ color: '#555' }}>
                      {pkg.description}
                    </Card.Text>
                  )}
                  {pkg.pricing && pkg.pricing.length > 0 && <PricingTabs pricing={pkg.pricing} />}
                  {pkg.whatsIncluded && (
                    <div className="mt-3">
                      <h6>What's Included:</h6>
                      <ul style={styles.additionalInfoList}>
                        {pkg.whatsIncluded.map((item, index) => (
                          <li key={index}>{item}</li>
                        ))}
                      </ul>
                    </div>
                  )}
                  {pkg.availability && (
                    <p className="text-center" style={{ color: '#888', fontStyle: 'italic' }}>
                      * {pkg.availability}
                    </p>
                  )}
                </Card.Body>
              </Card>
            </Col>
            <Col md={6}>
              <motion.h4
                style={styles.sectionSubTitle}
                initial="hidden"
                animate="visible"
                variants={{ hidden: { opacity: 0, y: -50 }, visible: { opacity: 1, y: 0 } }}
                transition={{ duration: 0.8, ease: 'easeInOut' }}
                className="text-center"
              >
                Additional Information
              </motion.h4>
              {renderAdditionalInfo(pkg.packageDetails, pkg.packageDetails.roomAvailability)}
            </Col>
          </Row>
        ))}

        {/* Call Us Now Section */}
        <Row className="mt-5">
          <Col className="text-center">
            <motion.div
              initial={{ scale: 0.9, opacity: 0.8 }}
              animate={{ scale: [1, 1.1, 1], opacity: 1 }}
              transition={{ duration: 1.5, repeat: Infinity, ease: 'easeInOut' }}
            >
              <CallNowButton />
            </motion.div>
          </Col>
        </Row>

        {/* FAQ Section */}
        <Row className="mt-5">
          <Col>
            <motion.h3
              style={styles.sectionTitle}
              initial="hidden"
              animate="visible"
              variants={{ hidden: { opacity: 0, y: -50 }, visible: { opacity: 1, y: 0 } }}
              transition={{ duration: 0.8, ease: 'easeInOut' }}
              className="text-center"
            >
              Frequently Asked Questions
            </motion.h3>
            <Accordion defaultActiveKey="0">
              {faqData.map((faq, index) => (
                <Accordion.Item
                  eventKey={index.toString()}
                  key={index}
                  className="custom-accordion-item"
                >
                  <Accordion.Header className="custom-accordion-header">
                    {faq.question}
                  </Accordion.Header>
                  <Accordion.Body style={{ fontSize: '1rem', color: '#555' }}>
                    {faq.answer}
                  </Accordion.Body>
                </Accordion.Item>
              ))}
            </Accordion>
          </Col>
        </Row>
      </Container>

      {/* Back To Top Button */}
      <BackToTop />
    </div>
  );
}
